<template>
  <!-- blogs start -->
  <div class="blogs-page">
    <PageLoader />
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/" style="color: #782462 !important;">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Guides
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- blog wrapper start -->
    <div class="blog-area-wrapper pt-40 pb-70">
      <div class="container">
        <div class="row">
          
          <div class="col-lg-12 order-first order-lg-last">
            <div class="product-shop-main-wrapper mb-50 row">
              <div class="col-lg-6 mb-3" v-for="(bolgs, i) in bolgs.data" :key="i">
                <div class="card" style="height: 11rem; overflow: hidden;">
                  <div class="row no-gutters" style="height: 100%;">
                    <div 
                      class="col-md-4" 
                      style="height: 100%;background-size: cover;background-position: center; position: relative;"
                      :style="{
                          backgroundImage: `url('${
                            publicPath + bolgs.img
                              ? publicPath + bolgs.img
                              : ''
                          }')`,
                        }"
                      >
                      <router-link :to="'/guides/' + bolgs.id" tag="a" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
                        <!-- <img :src="publicPath + bolgs.img" alt class="card-img" style="height: 100%;"> -->
                      </router-link>
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">
                          <router-link :to="'/guides/' + bolgs.id " tag="a" style="color: inherit;" >
                            {{ bolgs.title }}
                          </router-link>
                        </h5>
                        <div class="blog_desc" v-html="getPragraphLimit(bolgs.desc, 500)"></div>
                        <!-- <p class="card-text"><small class="text-muted">Posted at {{ bolgs.created_at | moment("ddd, DD-MM-YYYY") }}</small></p> -->
                      </div>
                    </div>
                  </div>
                </div>
            </div>

              <div class="paginatoin-area style-2 pt-35 pb-20">
                <nav aria-label="Page navigation example">
                  <pagination
                    :align="'center'"
                    :data="bolgs"
                    @pagination-change-page="getData"
                  >
                    <span slot="prev-nav">&lt; Previous</span>
                    <span slot="next-nav">Next &gt;</span>
                  </pagination>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog wrapper end -->
  </div>
  <!-- blogs end -->
</template>

<script>
import sideBarComp from "@/views/pages/sidebars/index.vue";
import PageLoader from "@/components/units/PageLoader.vue";
import $ from "jquery";
export default {
  name: "newsCopm",
  components: {
    sideBarComp,
    PageLoader
  },
  data() {
    return {
      active_el: 0,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 4000
        },
        speed: 3000,
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      bolgs: {},
      blogCategory: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted: function() {
   window.scrollTo(0, 0)
    this.getData();
    this.getblogCategory();

    // product view mode change js
    $(".product-view-mode a").on("click", function(e) {
      e.preventDefault();

      var shopProductWrap = $(".shop-product-wrap");
      var viewMode = $(this).data("target");

      $(".product-view-mode a").removeClass("active");
      $(this).addClass("active");
      shopProductWrap.removeClass("grid list column_3").addClass(viewMode);
    });
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    getRoutQuery() {
      return this.$route.query;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
      this.getblogCategory();
    },
    getRoutQuery() {
      this.getData();
      this.getblogCategory();
    }
  },
  methods: {
    activate: function(el) {
      this.active_el = el;
    },
    getData(page = 1) {
      this.loading = true;
      this.$http
        .get( "blog?category=guides&count=9&page=" + page )
        .then(response => {
          this.bolgs = response.data;
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getblogCategory() {
      this.loading = true;
      this.$urlmain
        .get("blogCategory-popular.json")
        .then(response => {
          this.blogCategory = response.data.data;
          response => (this.blogCategory = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getPragraphLimit(pragraph, num) {
      return pragraph.length > num
        ? pragraph.substring(0, num) + "..."
        : pragraph;
    }
  }
};
</script>
